export const useFormatters = () => {
    const fixedWhenNeeded = (value: number, p: number) => {
        let numberValue = Number(value);
        if (numberValue % 1 == 0) {
            return numberValue.toFixed(0);
        }

        return numberValue.toFixed(2);
    };

    return {
        fixedWhenNeeded
    };
};